<template>
    <Contato :showMenu="showMenu" :origem="origem" :contactOnly="false"/>
</template>

<script>
import Contato from "@/components/sections/Contato.vue";

export default {
    name: "HomeView",
    components: {
        Contato,
    },
    props: {
        showMenu: {
            type: Boolean,
            default: true
        },
        origem: {
            type: String,
            default: 'Não Informado'
        }
    },
};
</script>
